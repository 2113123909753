<!-- eslint-disable -->
<template>
  <loading v-model:active="isLoading" :is-full-page="fullPage" />
  <div class="row">
      <div class="col-lg-4">
        <div class="card br-t-white radius-lr10">
          <img class="card-img-top img-fluid radius-lr10" src="/img/bsi.png" alt="#">
          <div class="card-body">
            <ul class="list-inline">
              <li class="pr-5">BANK BSI</li>
              <li class="pr-5">Kode Bank : 451</li>
              <li class="pr-5">Nomor Rek VA. {{ '9000100' + userName }}</li>
            </ul>
            <h3>Rp. {{ $filters.formatRp(totalTagihan) }}</h3>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header card-default">
            <p id="idExsampel" class="text-muted" >
              Rincian tagihan pembayaran mahasiswa
            </p>
          </div>
          <div class="card-body padding-t-0">
            <template v-for="(row, num) in rinciTagihan" :key="row.id">
              <small class="text-muted text-fl">{{ num+1}}. {{ row.uraian }} </small>
              <p class="text-right mb-10"><small>Rp.</small> {{ $filters.formatRp(row.jumlah) }}</p>
            </template>
            <hr>
            <small class="text-muted text-fl">Total Tagihan</small>
            <p class="text-right"><small>Rp.</small> {{ $filters.formatRp(totalTagihan) }} </p>
            <button class="btn btn-success box-shadow btn-rounded mt-3 float-right">Download</button>
            <router-link to="/keuangan/tagihan" class="btn btn-warning box-shadow btn-rounded mt-3 float-right" >Kembali</router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <h3 class="card-title text-dark text-left">A. MOBILE BANKING – BANK SYARIAH INDONESIA (BSI) ex. BSM</h3>
              <ol class="card-text text-dark">
                <li>Login</li>
                <li>Pilih menu <strong>Bayar</strong></li>
                <li>Pilih Pembayaran <strong>Akademik</strong></li>
                <li>Isi Nama Akademik dengan Kode : <strong>5548 - Universitas Muhammadiyah Metro</strong></li>
                <li>Isi ID Pelanggan/Kode Bayar dengan 12 digit terakhir virtual account : <strong>{{ '0100' + userName }} </strong></li>
                <li>Tagihan yang muncul sudah otomatis  : <strong> Rp. {{ $filters.formatRp(totalTagihan) }} </strong></li>
                <li>Lanjutkan ke proses Pembayaran </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <h3 class="card-title text-dark text-left">B. ATM – BANK SYARIAH INDONESIA (BSI) ex. BSM</h3>
              <ol class="card-text text-dark">
                <li>Login</li>
                <li>Pilih menu <strong>Pembayaran</strong></li>
                <li>Pilih Pembayaran <strong>Akademik</strong></li>
                <li>Isi Nama Akademik dengan Kode : <strong>5548 - Universitas Muhammadiyah Metro</strong></li>
                <li>Isi ID Pelanggan/Kode Bayar dengan 12 digit terakhir virtual account : <strong> {{ '0100' + userName }} </strong></li>
                <li>Tagihan yang muncul sudah otomatis : <strong> Rp. {{ $filters.formatRp(totalTagihan) }} </strong></li>
                <li>Lanjutkan ke proses Pembayaran </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <h3 class="card-title text-dark text-left">C. MOBILE BANKING BANK LAIN </h3>
              <ol class="card-text text-dark">
                <li>Login</li>
                <li>Pilih menu <strong>Transfer</strong></li>
                <li>Pilih bank Tujuan ke <strong>Bank Syariah Indonesia</strong></li>
                <li>Isi No Rekening tujuan dengan nomor Virtual Account Mahasiswa : <strong> {{ '90055480100' + userName }} </strong></li>
                <li>
                  Isi Nominal Transfer dengan Total pembayaran masing-masing mahasiswa  : <strong>Rp. {{ $filters.formatRp(totalTagihan) }}</strong>
                </li>
                <li>Mohon untuk dicek kembali nama penerima yang muncul apakah sudah sesuai dengan nama mahasiswa yang akan
                  dibayarkan</li>
                <li>Lanjutkan ke proses Pembayaran </li>
              </ol>

            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <h3 class="card-title text-dark text-left">D. ATM BANK LAIN </h3>
              <ol class="card-text text-dark">
                <li>Login</li>
                <li>Pilih menu <strong>Transfer</strong></li>
                <li>Pilih bank Tujuan ke <strong>Bank Syariah Indonesia</strong></li>
                <li>Isi No Rekening tujuan dengan nomor Virtual Account Mahasiswa :
                  <strong>{{ '45190055480100' + userName }} </strong> <br>
                  <i><small class="text-danger">Ket. 451 : tiga digit awal adalah kode Bank Syariah Indonesia</small></i>
                </li>
                <li>
                  Isi Nominal Transfer dengan Total pembayaran <strong>Rp. {{ $filters.formatRp(totalTagihan) }} </strong> 
                </li>
                <li>Mohon untuk dicek kembali nama penerima yang muncul apakah sudah sesuai dengan nama mahasiswa yang akan
                  dibayarkan</li>
                <li>Lanjutkan ke proses Pembayaran </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="modal fade konfirmasi-modal" tabindex="-1" role="dialog" aria-labelledby="myDefaultModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true"
              class="fa fa-times"></span></button>
          <h5 class="modal-title" id="myDefaultModalLabel">Konfirmasi</h5>
        </div>
        <div class="modal-body">

        </div>
        <div class="modal-footer">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-multi-spaces: [2, { exceptions: { "VariableDeclarator": true } }] */
import { getTagihan } from '@/services/keu.service'
import { useStore } from 'vuex'
import Loading from 'vue-loading-overlay'
export default {
  name: 'SlipBSI',
  components: {
    Loading
  },
  data () {
    const store = useStore()
    const users = store.getters.user
    return {
      userName: users.username,
      profil: {},
      saldoKip: 0,
      totalTagihan: 0,
      rinciTagihan: [],
      isRegister: false,
      isLoading: false,
      fullPage: true,
      errorData: {}
    }
  },
  mounted () {
    this.handleTagihan()
  },
  methods: {
    async handleTagihan () {
      this.isLoading = true
      getTagihan({ nipd: this.userName }).then(response => {
        const rinci = response.records
        const total = response.total
        this.totalTagihan = total
        this.rinciTagihan = rinci
        this.isLoading = false
      }).catch(error => {
        this.errorData = (error.response) ? {} : {}
        this.isLoading = false
      })
    }
  }
}
</script>
